import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MailApprovalService from "services/MailApprovalService";
import { message } from "antd";
import { clearCount } from "./inboxSlice";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  error: null,
  generationLogs: [],
  generationLogsCount: 0,
  fullGenerationLogs: [],
  generationResponse: [],
  generateResponse: {},
  reGenerateResponse: {},
  reGenerateSave: {},
  editSave: {},
  approveMailRes: {},
  approveAllMailRes: null,
  editEmailRes: null,
  updateGenerationLogRes: null,
  bulkDeleteUniboxApprovalsRes: {},
  bulkUnreadUniboxApprovalsRes: {},
  deleteResponseObj: {},
  approveResponseObj: {},
  approveAllResponseObj: {},
};

const success = (msg) => {
  message.open({
    type: "success",
    content: msg,
  });
};

// Fetch fetchGenerationLogs
export const fetchGenerationLogs = createAsyncThunk(
  "mailGeneration/fetchGenerationLogs",
  async (params, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.getGenerationlogs(params);
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error fetching logs"
      );
    }
  }
);

// Fetch fetchUniboxGenerationLogs
export const fetchUniboxGenerationLogs = createAsyncThunk(
  "mailGeneration/fetchUniboxGenerationLogs",
  async ({channel_type, params, type}, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.getUniboxGenerationlogs(channel_type, params, type);
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error fetching logs"
      );
    }
  }
);

// Fetch fetchUniboxGenerationResponse
export const fetchUniboxGenerationResponse = createAsyncThunk(
  "mailGeneration/fetchUniboxGenerationResponse",
  async ({thread_id, response_id}, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.getUniboxGenerationResponse(thread_id, response_id);
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error fetching logs"
      );
    }
  }
);

export const bulkDeleteUniboxApprovals = createAsyncThunk(
  "mailGeneration/bulkDeleteUniboxApprovals",
  async ({params}, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.bulkDeleteUniboxApprovals(params);
      success("Successfully updated");
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error deleting thread"
      );
    }
  }
);

export const bulkUnreadUniboxApprovals = createAsyncThunk(
  "mailGeneration/bulkUnreadUniboxApprovals",
  async ({params}, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.bulkUnreadUniboxApprovals(params);
      success("Successfully updated");
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error deleting thread"
      );
    }
  }
);

export const markReadApprovalThread = createAsyncThunk(
  "mailGeneration/markReadApprovalThread",
  async (thread_id, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.markReadApprovalThread(thread_id);
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error updating thread"
      );
    }
  }
);

export const deleteResponse = createAsyncThunk(
  "mailGeneration/deleteResponse",
  async (response_id, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.deleteResponse(response_id);
      success("Successfully deleted");
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error updating thread"
      );
    }
  }
);

export const approveResponse = createAsyncThunk(
  "mailGeneration/approveResponse",
  async (response_id, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.approveResponse(response_id);
      success("Successfully approved");
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error updating thread"
      );
    }
  }
);

export const approveAllResponse = createAsyncThunk(
  "mailGeneration/approveAllResponse",
  async ({channel_type, params}, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.approveAllResponse(channel_type, params);
      success("Successfully approved");
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error updating thread"
      );
    }
  }
);

// Fetch fetchGenerationResponse
export const fetchGenerationResponse = createAsyncThunk(
  "mailGeneration/fetchGenerationResponse",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.getGenerationResponse(
        id,
        params
      );
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error fetching emails"
      );
    }
  }
);

export const fetchGenerateResponse = createAsyncThunk(
  "mailGeneration/fetchGenerateResponse",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.generateResponse(data);
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error generating response"
      );
    }
  }
);

export const fetchReGenerateResponse = createAsyncThunk(
  "mailGeneration/fetchReGenerateResponse",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.reGenerateResponse(data);
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error generating response"
      );
    }
  }
);

export const fetchUniboxReGenerateResponse = createAsyncThunk(
  "mailGeneration/fetchUniboxReGenerateResponse",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.uniboxReGenerateResponse(data);
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error generating response"
      );
    }
  }
);

export const fetchUniboxReGenerateSave = createAsyncThunk(
  "mailGeneration/fetchUniboxReGenerateSave",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.uniboxReGenerateSave(data);
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error generating response"
      );
    }
  }
);

export const fetchUniboxEditSave = createAsyncThunk(
  "mailGeneration/fetchUniboxEditSave",
  async ({response_id, data}, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.uniboxEditSave(response_id, data);
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error generating response"
      );
    }
  }
);

export const updateGenrationResponse = createAsyncThunk(
  "mailGeneration/updateGenrationResponse",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.updateGenerationResponse(data);
      success("Successfully updated");
      return { payload: data, data: response };
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error updateGenerationResponse"
      );
    }
  }
);

export const approveMailResponse = createAsyncThunk(
  "mailGeneration/putApproveMail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.approveMail(data);
      success("Successfully approved");

      return { payload: data, data: response };
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error updateGenerationResponse"
      );
    }
  }
);

export const approveAllMailResponse = createAsyncThunk(
  "mailGeneration/putApproveAllMail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.approveAllMail(data);
      success("Successfully approved");
      return { payload: data, data: response };
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error updateGenerationResponse"
      );
    }
  }
);

export const updateGenerationLog = createAsyncThunk(
  "mailGeneration/updateGenerationLog",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.updateGenerationLog(data);
      if (data?.data?.hasOwnProperty("isDeleted")) {
        success("Successfully deleted mail");
      }
      return { payload: data, data: response };
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error updateGenerationLog"
      );
    }
  }
);

export const mailGeneration = createSlice({
  name: "mailGeneration",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
      state.showMessage = false;
    },
    cleargenerationCount(state) {
      state.generationLogsCount = 0
    },
    cleargenerationLog(state) {
      state.generationLogs = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGenerationLogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGenerationLogs.fulfilled, (state, action) => {
        state.loading = false;
        // const currentState = current(state);
        state.generationLogs = action?.payload?.logs || [];
        state.generationLogsCount = action?.payload?.logCount || 0;
        state.showMessage = false;
      })
      .addCase(fetchGenerationLogs.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      .addCase(fetchUniboxGenerationLogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUniboxGenerationLogs.fulfilled, (state, action) => {
        state.loading = false;
        if (action.meta.arg.type === 'onscroll') {
          state.generationLogs = [...state.generationLogs, ...action?.payload?.paginated_results] || [];
        } else {
          state.generationLogs = action?.payload?.paginated_results || [];
        }

        state.generationLogsCount = action?.payload?.total_count || 0;
        state.showMessage = false;
      })
      .addCase(fetchUniboxGenerationLogs.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      .addCase(fetchUniboxGenerationResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUniboxGenerationResponse.fulfilled, (state, action) => {
        state.loading = false;
        state.generationResponse = action.payload;
        state.showMessage = false;
      })
      .addCase(fetchUniboxGenerationResponse.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      .addCase(bulkDeleteUniboxApprovals.pending, (state) => {
        state.loading = true;
      })
      .addCase(bulkDeleteUniboxApprovals.fulfilled, (state, action) => {
        state.loading = false;
        state.bulkDeleteUniboxApprovalsRes = action.payload;
        state.showMessage = false;
      })
      .addCase(bulkDeleteUniboxApprovals.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      .addCase(bulkUnreadUniboxApprovals.pending, (state) => {
        state.loading = true;
      })
      .addCase(bulkUnreadUniboxApprovals.fulfilled, (state, action) => {
        state.loading = false;
        state.bulkUnreadUniboxApprovalsRes = action.payload;
        state.showMessage = false;
      })
      .addCase(bulkUnreadUniboxApprovals.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      .addCase(deleteResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteResponse.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteResponseObj = action.payload;
        state.showMessage = false;
      })
      .addCase(deleteResponse.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      .addCase(approveResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveResponse.fulfilled, (state, action) => {
        state.loading = false;
        state.approveResponseObj = action.payload;
        state.showMessage = false;
      })
      .addCase(approveResponse.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      .addCase(approveAllResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveAllResponse.fulfilled, (state, action) => {
        state.loading = false;
        state.approveAllResponseObj = action.payload;
        state.showMessage = false;
      })
      .addCase(approveAllResponse.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      .addCase(fetchGenerationResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGenerationResponse.fulfilled, (state, action) => {
        state.loading = false;
        state.generationResponse = action.payload;
        state.showMessage = false;
      })
      .addCase(fetchGenerationResponse.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })
      // generate
      .addCase(fetchGenerateResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGenerateResponse.fulfilled, (state, action) => {
        state.loading = false;
        state.generateResponse = action.payload;
        state.showMessage = false;
      })
      .addCase(fetchGenerateResponse.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })
      // regenerate
      .addCase(fetchReGenerateResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchReGenerateResponse.fulfilled, (state, action) => {
        state.loading = false;
        state.reGenerateResponse = action.payload;
        state.showMessage = false;
      })
      .addCase(fetchReGenerateResponse.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      // unibox regenerate
      .addCase(fetchUniboxReGenerateResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUniboxReGenerateResponse.fulfilled, (state, action) => {
        state.loading = false;
        state.reGenerateResponse = action.payload;
        state.showMessage = false;
      })
      .addCase(fetchUniboxReGenerateResponse.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      // unibox regenerate save
      .addCase(fetchUniboxReGenerateSave.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUniboxReGenerateSave.fulfilled, (state, action) => {
        state.loading = false;
        state.reGenerateSave = action.payload;
        state.showMessage = false;
      })
      .addCase(fetchUniboxReGenerateSave.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      // unibox edit save
      .addCase(fetchUniboxEditSave.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUniboxEditSave.fulfilled, (state, action) => {
        state.loading = false;
        state.editSave = action.payload;
        state.showMessage = false;
      })
      .addCase(fetchUniboxEditSave.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      .addCase(updateGenrationResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateGenrationResponse.fulfilled, (state, action) => {
        state.loading = false;
        state.editEmailRes = action.payload;
        state.showMessage = false;
      })
      .addCase(updateGenrationResponse.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })
      .addCase(approveMailResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveMailResponse.fulfilled, (state, action) => {
        state.loading = false;
        state.approveMailRes = action.payload;
        state.showMessage = false;
      })
      .addCase(approveMailResponse.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })
      .addCase(approveAllMailResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveAllMailResponse.fulfilled, (state, action) => {

        state.loading = false;
        state.approveAllMailRes = action.payload;
        state.showMessage = false;
      })
      .addCase(approveAllMailResponse.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })
      .addCase(updateGenerationLog.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateGenerationLog.fulfilled, (state, action) => {
        state.loading = false;
        state.updateGenerationLogRes = action.payload;
        state.showMessage = false;
      })
      .addCase(updateGenerationLog.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      });
  },
});

export const { clearError, cleargenerationCount,cleargenerationLog } = mailGeneration.actions; // not used yet

export default mailGeneration.reducer;
