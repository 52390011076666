import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MetaDataService from "services/MetaDataServices";

export const initialState = {
    loading: false,
    message: "",
    showMessage: false,

    // People
    jobTitles: [],
    seniorityLevel: [],
    function_and_division: [],

    // Location
    country: [],
    continent: [],
    region: [],

    // Company
    companySize: [],
    revenue: [],
    funding: [],
    industry_sector: [],

    // persona
    persona: [],

    // others
    sectors: [],
    advancedTargeting: [],
    outBoundKeywords: [],
    toneOfVoice: [],
    optimisationMatrix:[],
    error: null,
};

// Pepole related Api calls

export const getJobTitles = createAsyncThunk(
    "metaData/getjobTitles",
    async () => {
        try {
            const response = await MetaDataService.getjobTitles();
            return response.job_titles;
        } catch (err) {
            return err.response?.data?.message || "Error";
        }
    }
);
export const getSeniorityLevel = createAsyncThunk(
    "metaData/getSeniorityLevel",
    async () => {
        try {
            const response = await MetaDataService.getjSeniortyLevel();

            return response.seniority_level;
        } catch (err) {
            return err.response?.data?.message || "Error";
        }
    }
);
export const getfunctionandDivisions = createAsyncThunk(
    "metaData/getfunctionandDivisions",
    async () => {
        try {
            const response = await MetaDataService.getfunctionandDivisions();

            return response.function_and_division;
        } catch (err) {
            return err.response?.data?.message || "Error";
        }
    }
);

// Location related api

export const getCountries = createAsyncThunk(
    "metaData/getCountries",
    async () => {
        try {
            const response = await MetaDataService.getCountry();
            return response.countries;
        } catch (err) {
            return err.response?.message || "Error";
        }
    }
);
export const getContinent = createAsyncThunk(
    "metaData/getContinent",
    async () => {
        try {
            const response = await MetaDataService.getContinent();

            return response.continents;
        } catch (err) {
            return err.response?.message || "Error";
        }
    }
);
export const getRegion = createAsyncThunk("metaData/getRegion", async () => {
    try {
        const response = await MetaDataService.getRegion();
        return response.regions;
    } catch (err) {
        return err.response?.message || "Error";
    }
});

// Company  related api

export const getCompanySize = createAsyncThunk(
    "metaData/getCompanySize",
    async () => {
        try {
            const response = await MetaDataService.getCompanySize();
            return response.company_size;
        } catch (err) {
            return err.response?.message || "Error";
        }
    }
);
export const getRevenue = createAsyncThunk(
    "metaData/getRevenue",
    async () => {
        try {
            const response = await MetaDataService.getRevenue();
            return response.revenue;
        } catch (err) {
            return err.response?.message || "Error";
        }
    }
);
export const getFunding = createAsyncThunk("metaData/getFunding", async () => {
    try {
        const response = await MetaDataService.getFunding();
        return response.funding;
    } catch (err) {
        return err.response?.message || "Error";
    }
});
export const getIndustrysector = createAsyncThunk(
    "metaData/getIndustrysector",
    async () => {
        try {
            const response = await MetaDataService.getIndustrysector();
            return response.industry_sector;
        } catch (err) {
            return err.response?.message || "Error";
        }
    }
);

// Persona
export const getPersona = createAsyncThunk(
    "metaData/getPersona",
    async () => {
        try {
            const response = await MetaDataService.getPersona();
            return response.persona;
        } catch (err) {
            return err.response?.message || "Error";
        }
    }
);

export const getOptimisation = createAsyncThunk(
    "metaData/getOptimization",
    async () => {
        try {
            const response = await MetaDataService.getOptimization();
            return response.optimisation_metrics;
        } catch (err) {
            return err.response?.message || "Error";
        }
    }
);

export const getSectors = createAsyncThunk("metaData/getSectors", async () => {
    try {
        const response = await MetaDataService.getSectors();
        return response.sectors;
    } catch (err) {
        return err.response?.data?.message || "Error";
    }
});

export const getOutboundKeywords = createAsyncThunk(
    "metaData/getOutboundKeywords",
    async () => {
        try {
            const response = await MetaDataService.getOutboundKeywords();
            return response.sectors;
        } catch (err) {
            return err.response?.data?.message || "Error";
        }
    }
);

export const getAdvancedSettings = createAsyncThunk(
    "metaData/getAdvancedTargetting",
    async () => {
        try {
            const response = await MetaDataService.getAdvancedTargetting();
            return response?.advanced_targeting;
        } catch (err) {
            return err.response?.data?.message || "Error";
        }
    }
);

export const getToneOfVoice = createAsyncThunk(
    "metaData/getToneOfVoice",
    async () => {
        try {
            const response = await MetaDataService.getToneOfVoice();
            return response?.tone_of_voice;
        } catch (err) {
            return err.response?.data?.message || "Error";
        }
    }
);

export const metaDataSlice = createSlice({
    name: "metaData",
    initialState,
    reducers: {
        clearError(state) {
            state.error = null;
            state.showMessage = false;
        },
    },
    extraReducers: (builder) => {
        builder
            //People:- get jobTitles
            .addCase(getJobTitles.pending, (state) => {
                state.loading = true;
            })
            .addCase(getJobTitles.fulfilled, (state, action) => {
                state.loading = true;
                state.jobTitles = action.payload;
            })
            .addCase(getJobTitles.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })

            //People:- get seniority level
            .addCase(getSeniorityLevel.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSeniorityLevel.fulfilled, (state, action) => {
                state.loading = true;
                state.seniorityLevel = action.payload;
            })
            .addCase(getSeniorityLevel.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })

            //People:- get function and divisions
            .addCase(getfunctionandDivisions.pending, (state) => {
                state.loading = true;
            })
            .addCase(getfunctionandDivisions.fulfilled, (state, action) => {
                state.loading = true;
                state.function_and_division = action.payload;
            })
            .addCase(getfunctionandDivisions.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })

            // Locations:- get countries
            .addCase(getCountries.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCountries.fulfilled, (state, action) => {
                state.loading = true;
                state.country = action.payload;
            })
            .addCase(getCountries.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })

            // Locations:- get continents
            .addCase(getContinent.pending, (state) => {
                state.loading = true;
            })
            .addCase(getContinent.fulfilled, (state, action) => {
                state.loading = true;
                state.continent = action.payload;
            })
            .addCase(getContinent.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })

            // Locations:- get regions
            .addCase(getRegion.pending, (state) => {
                state.loading = true;
            })
            .addCase(getRegion.fulfilled, (state, action) => {
                state.loading = true;
                state.region = action.payload;
            })
            .addCase(getRegion.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })

            // Company :- get company size
            .addCase(getCompanySize.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCompanySize.fulfilled, (state, action) => {
                state.loading = true;
                state.companySize = action.payload;
            })
            .addCase(getCompanySize.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })

            // Company :- get company Revenue
            .addCase(getRevenue.pending, (state) => {
                state.loading = true;
            })
            .addCase(getRevenue.fulfilled, (state, action) => {
                state.loading = true;
                state.revenue = action.payload;
            })
            .addCase(getRevenue.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })

            // Company :- get company funding
            .addCase(getFunding.pending, (state) => {
                state.loading = true;
            })
            .addCase(getFunding.fulfilled, (state, action) => {
                state.loading = true;
                state.funding = action.payload;
            })
            .addCase(getFunding.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })

            // Company :- get company industry and sector
            .addCase(getIndustrysector.pending, (state) => {
                state.loading = true;
            })
            .addCase(getIndustrysector.fulfilled, (state, action) => {
                state.loading = true;
                state.industry_sector = action.payload;
            })
            .addCase(getIndustrysector.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })

            // Persona : get persona 
            .addCase(getPersona.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPersona.fulfilled, (state, action) => {
                state.loading = true;
                state.persona = action.payload;
            })
            .addCase(getPersona.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })


            .addCase(getSectors.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSectors.fulfilled, (state, action) => {
                state.loading = true;
                state.sectors = action.payload;
            })
            .addCase(getSectors.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })








            // get outboundKeywords
            .addCase(getOutboundKeywords.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOutboundKeywords.fulfilled, (state, action) => {
                state.loading = true;
                state.outBoundKeywords = action.payload;
            })
            .addCase(getOutboundKeywords.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })

            // get Advanced targeting
            .addCase(getAdvancedSettings.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAdvancedSettings.fulfilled, (state, action) => {
                state.loading = true;
                state.advancedTargeting = action.payload;
            })
            .addCase(getAdvancedSettings.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })

            // get OptimisationMatrix
            .addCase(getOptimisation.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOptimisation.fulfilled, (state, action) => {
                state.loading = true;
                state.optimisationMatrix = action.payload;
            })
            .addCase(getOptimisation.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })

            // get Tone of Voice
            .addCase(getToneOfVoice.pending, (state) => {
                state.loading = true;
            })
            .addCase(getToneOfVoice.fulfilled, (state, action) => {
                state.loading = true;
                state.toneOfVoice = action.payload;
            })
            .addCase(getToneOfVoice.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            });
    },
});

export const { clearError } = metaDataSlice.actions;

export default metaDataSlice.reducer;
