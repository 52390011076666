import fetch from "auth/FetchInterceptor"

const MailManagementService = {
  getEmailAddresses: () => {
    try {
      return fetch({
        url: "/mail/mail_mng",
        method: "get",
      })
    } catch (error) {
      throw new Error(error.message || "Failed to fetch email addresses")
    }
  },
  toggleUseMailbox: async function (account) {
    try {
      const response = await fetch({
        url: `/mail/mail_mng/${account}/toggle`,
        method: "patch",
      })
      return response
    } catch (error) {
      console.error("Error toggling mailbox:", error)
      throw error // Re-throw the error for proper handling
    }
  },
  disconnectEmailAddress: async function (account) {
    try {
      const response = await fetch({
        url: `/mail/mail_mng/${account}/disconnect`,
        method: "patch",
      })
      return response
    } catch (error) {
      throw error
    }
  },
  getAuthCode: (provider, scope) => {
    try {
      return fetch({
        url: `/mail/auth_code`,
        method: "post",
        data: {
          email_provider: provider,
          scope_type: scope,
        },
      })
    } catch (error) {
      throw new Error(error.message || "Failed to get auth uri")
    }
  },

  getEmailHealth: (provider) => {
    try {
      return fetch({
        url: `/mail/mailbox-health`,
        method: "get",
      })
    } catch (error) {
      throw new Error(error.message || "Failed to get auth uri")
    }
  },

  getTrainingEmailAddresses: () => {
    try {
      return fetch({
        url: "/mail/listing/training-mails",
        method: "get",
      })
    } catch (error) {
      throw new Error(error.message || "Failed to fetch email addresses")
    }
  },

  getLinkedInAccounts: () => {
    try {
      return fetch({
        url: "/linkedin/accounts/",
        method: "get",
      })
    } catch (error) {
      throw new Error(error.message || "Failed to fetch linkedIn accounts")
    }
  },

  submitLinkedInAccountDetail: (username, password) => {
    try {
      return fetch({
        url: "/linkedin/account/create/",
        method: "post",
        data: {
          username,
          password,
        },
      })
    } catch (error) {
      throw new Error(error.message || "Failed to add account")
    }
  },
  createAccountLink: () => {
    try {
      return fetch({
        url: "/linkedin/account/link/",
        method: "get",
      })
    } catch (error) {
      throw new Error(error.message || "Failed to creare account link")
    }
  },
  submitCaptchaResponse: (account_id, code) => {
    try {
      return fetch({
        url: "/linkedin/account/solve_code_point/",
        method: "post",
        data: {
          account_id,
          code,
        },
      })
    } catch (error) {
      throw new Error(error.message || "Failed to add account")
    }
  },
  deleteLinkedInAccount: (account_id) => {
    try {
      return fetch({
        url: `/linkedin/account/${account_id}/`,
        method: "delete",
      })
    } catch (error) {
      throw new Error(error.message || 'Failed to delete account')
    }
  }
}

export default MailManagementService
