import React from "react";

export const GoogleSvg = (props) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.14 9.70456C18.14 9.06637 18.0827 8.45274 17.9764 7.86365H9.5V11.345H14.3436C14.135 12.47 13.5009 13.4232 12.5477 14.0614V16.3196H15.4564C17.1582 14.7527 18.14 12.4455 18.14 9.70456Z"
        fill="#4285F4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.5 18.5C11.93 18.5 13.9673 17.6941 15.4564 16.3195L12.5477 14.0613C11.7418 14.6013 10.7109 14.9204 9.5 14.9204C7.15591 14.9204 5.17182 13.3372 4.46409 11.21H1.45728V13.5418C2.93818 16.4831 5.98182 18.5 9.5 18.5Z"
        fill="#34A853"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.46409 11.2099C4.28409 10.6699 4.18182 10.0931 4.18182 9.49995C4.18182 8.90677 4.28409 8.32995 4.46409 7.78995V5.45813H1.45727C0.847727 6.67313 0.5 8.04768 0.5 9.49995C0.5 10.9522 0.847727 12.3268 1.45727 13.5418L4.46409 11.2099Z"
        fill="#FBBC05"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.5 4.07955C10.8214 4.07955 12.0077 4.53364 12.9405 5.42545L15.5218 2.84409C13.9632 1.39182 11.9259 0.5 9.5 0.5C5.98182 0.5 2.93818 2.51682 1.45728 5.45818L4.46409 7.79C5.17182 5.66273 7.15591 4.07955 9.5 4.07955Z"
        fill="#EA4335"
      />
    </svg>
  );
};
