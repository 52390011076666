import Icon, { LogoutOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Avatar, Checkbox, Col, Dropdown, Row, Typography } from "antd";
import Flex from "components/shared-components/Flex";
import {
  FONT_SIZES,
  FONT_WEIGHT,
  MEDIA_QUERIES,
  SPACER,
} from "constants/ThemeConstant";
import { useProfile } from "hooks/useProfile";
import { useEffect, useMemo, useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "store/slices/authSlice";
import { onHeaderNavColorChange, onSwitchTheme } from "store/slices/themeSlice";
import NavItem from "./NavItem";
import { SettingsSvg } from "assets/svg/settings";
import { useNavigate } from "react-router-dom";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { truncateString } from "views/app-views/apps/project/scrumboard/utils";

const SettingsIcon = (props) => <Icon component={SettingsSvg} {...props} />;

const { Text } = Typography;

const Icons = styled.div(() => ({
  fontSize: FONT_SIZES.LG,
}));

const Profile = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const UserInfo = styled("div")`
  padding-left: ${SPACER[2]};

  @media ${MEDIA_QUERIES.MOBILE} {
    display: none;
  }
`;

const Name = styled.div(() => ({
  fontWeight: FONT_WEIGHT.SEMIBOLD,
  fontSize: "14px",
  lineHeight: "0.8rem",
}));

const Title = styled.span(() => ({
  opacity: 0.8,
  fontSize: "10px",
  lineHeight: "0.5rem",
}));

const MenuItemSignOut = (props) => {
  const dispatch = useDispatch();

  const handleSignOut = () => {
    dispatch(signOut());
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <div onClick={handleSignOut}>
      <Flex alignItems="center" gap={SPACER[2]}>
        <Icons>
          <LogoutOutlined />
        </Icons>
        <span>{props.label}</span>
      </Flex>
    </div>
  );
};

const AccountSetting = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSetting = () => {
    // dispatch(signOut());
    navigate(`${APP_PREFIX_PATH}/apps/settings`);
  };

  return (
    <div onClick={handleSetting}>
      <Flex alignItems="center" gap={SPACER[2]}>
        <SettingsIcon />

        <span>{props.label}</span>
      </Flex>
    </div>
  );
};

const ThemeSetting = (props) => {
  const dispatch = useDispatch();
  const { switcher, themes } = useThemeSwitcher();

  const toggleTheme = (isChecked) => {
    onHeaderNavColorChange("");
    const changedTheme = isChecked ? "dark" : "light";
    dispatch(onSwitchTheme(changedTheme));
    switcher({ theme: themes[changedTheme] });
  };

  return (
    <div>
      <Flex alignItems="center" gap={SPACER[2]}>
        <Checkbox
          //   defaultChecked={isItemPresent}
          onChange={toggleTheme}
          className="custom-checkbox"
        />
        <span>{props.label}</span>
      </Flex>
    </div>
  );
};

const items = [
  {
    key: "Setting",
    label: <AccountSetting label="Settings" />,
  },
  {
    key: "Sign Out",
    label: <MenuItemSignOut label="Sign Out" />,
  },
];

const UserAvatar = ({ imageSrc, name }) => {
  // Get the first letter of the name
  const firstLetter = name ? name.charAt(0).toUpperCase() : "";

  return (
    <Avatar
      src={imageSrc} // This will render the image if available
      shape="square"
      style={{
        backgroundColor: imageSrc ? "transparent" : "#5F26C8",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "36px",
        height: "36px",
      }}
    >
      {!imageSrc && firstLetter}{" "}
      {/* Render the first letter if no image source */}
    </Avatar>
  );
};

export const NavProfile = ({ mode }) => {
  const { profileData } = useProfile();
  const [nameVisible, setNameVisible] = useState(false);
  const navCollapsed = useSelector((state) => state.theme.navCollapsed);

  useEffect(() => {
    // Delay the visibility of the name
    if (!navCollapsed) {
      const timer = setTimeout(() => {
        setNameVisible(true); // Show the name after a delay
      }, 300); // 300ms delay for the name to appear after Avatar
      return () => clearTimeout(timer); // Cleanup timer on component unmount or change
    } else {
      setNameVisible(false); // Hide the name when collapsed
    }
  }, [navCollapsed]);

  return (
    <Dropdown
      placement="topLeft"
      menu={{ items }}
      overlay={items}
      overlayStyle={{
        position: "absolute",
        left: navCollapsed ? "4rem" : "1rem",
      }}
      offset={[20, 10]} // [x, y] -> x for right-left, y for top-bottom
    >
      <NavItem mode={"dark"}>
        <Profile>
          {/* <Avatar src="/img/avatars/thumb-1.jpg" /> */}
          <UserAvatar name={profileData?.username} />
          {nameVisible && (
            <UserInfo
              className="profile-text"
              style={{
                top: "0%", // Adjust if necessary
                left: "3rem", // Adjust to fit your layout
                right: "0",
                transform: navCollapsed ? "translateY(-10px)" : "translateY(0)", // Smooth slide effect
                transition: "opacity 8s ease 7s, transform 7s ease", // Smooth transition for both properties
                pointerEvents: navCollapsed ? "none" : "auto", // Disable interaction when collapsed
              }}
            >
              <Name>{truncateString(profileData?.username,16)}</Name>
              <Title>Admin</Title>
            </UserInfo>
          )}
        </Profile>
      </NavItem>
    </Dropdown>
  );
};

const getTimeLeft = (expiryDate) => {
  const now = new Date();
  const expiry = new Date(expiryDate); // Convert expiryDate to Date object
  const diff = expiry - now; // Calculate the difference in milliseconds

  if (diff <= 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};

const CustomCountdown = ({ ExpiryDatae }) => {
  const dispatch = useDispatch();
  // Memoize time left to avoid unnecessary recalculations on each render
  const timeLeft = useMemo(() => getTimeLeft(ExpiryDatae), [ExpiryDatae]);

  const handleCalendlyOpenModal = () => {
    // Open the Calendly URL directly without changing state unnecessarily
    window.location.href = "https://www.revrag.ai/book-a-demo";
  };

  return (
    <div
      className="custom-countdown"
      style={{
        display: "flex",
        width: "100%",
        color: "#000",
      }}
    >
      {timeLeft?.days !== 0
        ? `Your free trial will expire in ${timeLeft?.days || 0} days.`
        : `Your free trial has expired. Please`}
      <span
        className="upgrade-link"
        style={{
          marginLeft: "0.2rem",
          marginRight: "0.2rem",
          color: "#5f26c9",
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={handleCalendlyOpenModal}
      >
        Upgrade
      </span>
      {timeLeft?.days !== 0
        ? `now to retain access to all features and benefits.`
        : `now to continue accessing all features and benefits.`}
    </div>
  );
};

export const NavCounter = ({ mode }) => {
  const { profileData } = useProfile();
  return (
    <NavItem>
      <Row gutter={16}>
        <Col xs={0} sm={24} span={12}>
          <CustomCountdown ExpiryDatae={profileData?.freeTrialEndDate} />
        </Col>
      </Row>
    </NavItem>
  );
};

export default NavProfile;
