import Icon from "@ant-design/icons";
import { Avatar, Tooltip } from "antd";
import { GoogleSvg } from "assets/svg/google";
import { MicroSoftSvg } from "assets/svg/microSoft";
import React from "react";
import { labels, membersDetail } from "./ScrumboardData";

import BreakupMailIcon from "assets/svg/breakup_mail_icon.svg";
import ColdMailIcon from "assets/svg/cold_mail_icon.svg";
import DeliveredMailIcon from "assets/svg/delivered_mail_icon.svg";
import FollowupMailIcon from "assets/svg/followup_mail_icon.svg";
import LinkedinIcon from "assets/svg/linkedin_mail_icon.svg";

const MicrosoftIcon = (props) => <Icon component={MicroSoftSvg} {...props} />;
const GoogleIcon = (props) => <Icon component={GoogleSvg} {...props} />;

const createUID = (len) => {
  const buf = [],
    chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
    charlen = chars.length,
    length = len || 32;

  for (let i = 0; i < length; i++) {
    buf[i] = chars.charAt(Math.floor(Math.random() * charlen));
  }
  return buf.join("");
};

export const modalModeTypes = (number) => {
  switch (number) {
    case 0:
      return "ADD_CARD";
    case 1:
      return "EDIT_CARD";
    case 2:
      return "ADD_BOARD";
    default:
      return "";
  }
};

export const getLabelsColor = (label) => {
  if (label) {
    const color = labels.filter((elm) => label === elm.label)[0].color;
    return color;
  }
  return;
};

export const getMember = (id) => {
  if (id) {
    const member = membersDetail.filter((elm) => id === elm.id)[0];
    if (member) {
      return <Avatar src={member.img}></Avatar>;
    }
  }
  return;
};

export const getCover = (id, attachments) => {
  if (id) {
    const cover = attachments.filter((elm) => id === elm.id)[0].src;
    return cover;
  }
  return;
};

export const AssigneeAvatar = ({ id, size = 20, name, chain }) => {
  if (id) {
    const member = membersDetail.filter((elm) => id === elm.id)[0];
    if (member) {
      return (
        <div className={`d-flex ${chain ? "ml-n2" : ""}`}>
          <Tooltip title={name ? "" : member.name}>
            <Avatar
              className="cursor-pointer"
              size={size}
              src={member.img}
              style={chain ? { border: "2px solid #fff" } : {}}
            ></Avatar>
          </Tooltip>
          {name ? (
            <span className="mb-0 ml-2 font-weight-semibold">
              {member.name}
            </span>
          ) : null}
        </div>
      );
    }
  }
  return null;
};

export const createCardObject = () => {
  return {
    id: createUID(10),
    name: "",
    description: "",
    cover: "",
    members: [],
    labels: [],
    attachments: [],
    checklists: [],
    comments: [],
    dueDate: null,
  };
};

export const createCommentObject = () => {
  return {
    id: createUID(10),
    name: "Eileen Horton",
    src: "/img/avatars/thumb-1.jpg",
    message: "",
    date: new Date(),
  };
};

// export const modifyCampaignFormData = (data) => {
// 	const transformedCampaign = {
// 		...data,
// 		features: data.features.map(feature => feature.data),
// 		painPoints: data.painPoints.map(painPoint => painPoint.data),
// 		proofOfPoints: data.proofOfPoints.map(proofOfPoint => proofOfPoint.data),
// 		testimonials:data.testimonials.map(proofOfPoints => proofOfPoints.data)
// 	};
// 	return transformedCampaign

// }

// export const modifyCampaignFormData = (data) => {
//   // Transform the data
//   const transformedCampaign = {
//     ...data,
//     features: data.features.map((feature) => feature.data),
//     painPoints: data.painPoints.map((painPoint) => painPoint.data),
//     proofOfPoints: data.proofOfPoints.map((proofOfPoint) => proofOfPoint.data),
//     testimonials: data.testimonials.map((testimonial) => testimonial.data),
//   };

//   // Function to check if a value is not empty, null, or undefined
//   const isValidValue = (value) =>
//     value !== null &&
//     value !== undefined &&
//     (typeof value === "string" ? value.trim() !== "" : true);

//   // Filter out empty, null, or undefined values
//   const filteredCampaign = Object.fromEntries(
//     Object.entries(transformedCampaign).filter(([key, value]) => {
//       if (Array.isArray(value)) {
//         // Filter out empty arrays
//         return value.length > 0;
//       }
//       // Filter out null, undefined, or empty string values
//       return isValidValue(value);
//     })
//   );

//   return filteredCampaign;
// };

export const modifyCampaignFormData = (data) => {
  // Transform the data
  const transformedCampaign = {
    ...data,
    features: data.features.map((feature) => feature.data),
    painPoints: data.painPoints.map((painPoint) => painPoint.data),
    proofOfPoints: data.proofOfPoints.map((proofOfPoint) => proofOfPoint.data),
    testimonials: data.testimonials.map((testimonial) => testimonial.data),
  };

  // Function to check if a value is not empty, null, or undefined
  const isValidValue = (value) =>
    value !== null &&
    value !== undefined &&
    (typeof value === "string" ? value.trim() !== "" : true);

  // Filter out empty, null, or undefined values, excluding senderSettingsList
  const filteredCampaign = Object.fromEntries(
    Object.entries(transformedCampaign)
      .filter(([key, value]) => key !== "senderSettingsList") // Exclude senderSettingsList
      .filter(([key, value]) => {
        if (Array.isArray(value)) {
          // Filter out empty arrays
          return value.length > 0;
        }
        // Filter out null, undefined, or empty string values
        return isValidValue(value);
      })
  );

  return filteredCampaign;
};


export const modifyCampaignFormDataforEdit = (
  originalFormData,
  campaignData
) => {
  const transformedData = {
    // Basic campaign details
    campaignName: originalFormData?.campaignName || "",
    campaignId: originalFormData?.campaignId || "",

    // Targeting
    leadsCsvUrl: originalFormData?.leads?.leadsCsvUrl || "",
    isLeadCsvUploaded: originalFormData?.leads?.isLeadCsvUploaded || false,

    peopleTargetFilter: {
      jobTitleList: originalFormData?.peopleTargetFilter?.jobTitleList || [],
      seniorityLevelList:
        originalFormData?.peopleTargetFilter?.seniorityLevelList || [],
      functionAndDivisionList:
        originalFormData?.peopleTargetFilter?.functionAndDivisionList || [],
    },

    locationTargetFilter: {
      countries: originalFormData?.locationTargetFilter?.countries || [],
      continent: originalFormData?.locationTargetFilter?.continent || [],
      region: originalFormData?.locationTargetFilter?.region || [],
    },

    companyTargetFilter: {
      companyList: originalFormData?.companyTargetFilter?.companyList || [],
      companySize: originalFormData?.companyTargetFilter?.companySize || [],
      fundingList: originalFormData?.companyTargetFilter?.fundingList || [],
      revenueList: originalFormData?.companyTargetFilter?.revenueList || [],
      industryAndSectorList:
        originalFormData?.companyTargetFilter?.industryAndSectorList || [],
    },
    persona: originalFormData?.persona || [],

    extraTargetFilter: {
      competitors: {
        list: originalFormData?.extraTargetFilter?.competitors?.list || [],
        csv_url:
          originalFormData?.extraTargetFilter?.competitors?.csv_url || "",
      },
      technographics: {
        list: originalFormData?.extraTargetFilter?.technographics?.list || [],
        csv_url:
          originalFormData?.extraTargetFilter?.technographics?.csv_url || "",
      },
      psychographics: {
        list: originalFormData?.extraTargetFilter?.psychographics?.list || [],
        csv_url:
          originalFormData?.extraTargetFilter?.psychographics?.csv_url || "",
      },
      keywords: {
        list: originalFormData?.extraTargetFilter?.keywords?.list || [],
        csv_url: originalFormData?.extraTargetFilter?.keywords?.csv_url || "",
      },
      blacklistEmailDomainList:
        originalFormData?.blacklistEmailDomainList || null,
    },

    // Pitch
    website: originalFormData?.website || "",
    salesPitch: originalFormData?.salesPitch || "",
    features: originalFormData?.features || [],
    painPoints: originalFormData?.painPoints || [],
    proofOfPoints: originalFormData?.proofOfPoints || [],
    testimonials: originalFormData?.testimonials || [],

    // Outreach
    language: originalFormData?.language || "English",
    senderSettings: originalFormData?.senderSettings || {
      companyWebsite: "",
      senderCompany: "",
      senderContact: "",
      senderEmail: "",
      senderJobTitle: "",
      senderName: "",
    },
    calendarLink: originalFormData?.calendarLink || "",
    toneOfVoice: originalFormData?.toneOfVoice || "",
    optimisationMetrics: originalFormData?.originalFormData || "",
    playbook: originalFormData?.playbook || [],

    // Settings
    isFullAutopilot: originalFormData?.isFullAutopilot || false,
    isActiveCampaign: originalFormData?.isActiveCampaign || false,
    isCRMIntegrate: originalFormData?.isCRMIntegrate || false,
    dncIfCRM: originalFormData?.dncIfCRM || false,
    dncIfOtherCampaign: originalFormData?.dncIfOtherCampaign || false,
    dncIfTeammateCampaign: originalFormData?.dncIfTeammateCampaign || false,
    senderSettingsList: {
      sender_settings_array:
        originalFormData?.senderSettingsList?.sender_settings_array || [],
    },

    // Metadata
    organizationId: originalFormData?.organizationId || "",
    totalLeadCount: originalFormData?.totalLeadCount || 0,
  };

  // Updating transformed data with campaignData
  Object.keys(campaignData).forEach((key) => {
    if (campaignData[key] !== undefined && campaignData[key] !== null) {
      // Special handling for features, painPoints, proofOfPoints, and testimonials
      if (
        key === "features" ||
        key === "painPoints" ||
        key === "proofOfPoints" ||
        key === "testimonials"
      ) {
        transformedData[key] = (campaignData[key] || []).map((item, index) => ({
          id: index + 1,
          data: item,
        }));
      } else {
        // General case for other fields
        transformedData[key] = campaignData[key];
      }
    }
  });

  return transformedData;
};

export function isNullOrUndefinedOrEmpty(input) {
  return (
    input === undefined ||
    input === null ||
    input === "" ||
    (Array.isArray(input) && input.length === 0)
  );
}

export const getEmailProviderIcon = (emailProvider) => {
  switch (emailProvider) {
    case "GMAIL":
      return <GoogleIcon />;
    case "OUTLOOK":
      return <MicrosoftIcon />;
    default:
      return null;
  }
};

export const getBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    if (!(file instanceof Blob)) {
      reject(new Error("Parameter is not a valid Blob or File"));
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

export const handlePreview = async (file) => {
  try {
    const preview = await getBase64(file);
    return preview; // Return only the preview
  } catch (error) {
    console.error("Error generating preview:", error);
    throw error; // Rethrow the error if needed
  }
};

export const getFileType = async (file) => {
  return new Promise(async (resolve) => {
    const mimeType = file.type;
    let result;
    let prev = await handlePreview(file);
    if (mimeType.startsWith("image/")) {
      result = { type: "Image", name: file?.name, data: prev };
    } else if (mimeType === "application/pdf") {
      result = { type: "PDF", name: file?.name, data: prev };
    } else if (mimeType === "application/json") {
      result = { type: "JSON", name: file?.name, data: prev };
    } else if (mimeType === "text/csv" || mimeType === "application/csv") {
      result = { type: "CSV", name: file?.name, data: prev };
    } else if (mimeType.startsWith("application/")) {
      result = { type: "Document", name: file?.name, data: prev };
    } else {
      result = "Unknown file type";
    }

    resolve(result);
  });
};

export const getFileImage = (type) => {
  switch (type) {
    case "Image":
      return "/img/revrag/image.png";
    case "PDF":
      return "/img/revrag/pdf.png";
    case "JSON":
      return "/img/revrag/fla.png";
    case "CSV":
      return "/img/revrag/csv.png";
    case "Document":
      return "/img/revrag/doc.png";
    default:
      break;
  }
};

export const returnSequenceTitle = (type) => {
  switch (type) {
    case "START_EMAIL":
      return "Starter Email";
    case "FOLLOW_UP_EMAIL":
      return "Follow-up Email";
    case "BREAKUP_EMAIL":
      return "Breakup Email";
    case "LINKDIN_CONNECTION":
      return "LinkedIn Connection";
    case "LINKEDIN_MESSAGE":
      return "LinkedIn message";
    default:
      break;
  }
};


export const formatTextForQuill = (text) => {
  return text
    .split("\n")
    .map((line, index) => `<p key=${index}>${line}</p>`)
    .join("");
};


export const capitalizeFirstLetter = (input) => {
  if (!input || typeof input !== "string") {
    return "";
  }
  return input.charAt(0).toUpperCase() + input.slice(1);
}



export const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength - 3) + "...";
  }
  return str;
};


export const entity_tags = {
  // START_EMAIL: {
  //   color: "#CC8100",
  //   border: "1px solid #FCF4DA",
  //   background: "#FEFCF6",
  //   text: "Start-up mail",
  //   icon: MailIcon,
  // },
  FOLLOW_UP_EMAIL: {
    color: "#CC8100",
    border: "1px solid #FCF4DA",
    background: "#FEFCF6",
    text: "Follow-up mail",
    icon: FollowupMailIcon,
  },
  BREAKUP_EMAIL: {
    color: "#E81717",
    border: "1px solid #FCE3E3",
    background: "#FFFAFA",
    text: "Break-up mail",
    icon: BreakupMailIcon,
  },
  LINKEDIN_CONNECTION: {
    color: "#1C87EB",
    border: "1px solid #E3F0FD",
    background: "#FAFDFF",
    text: "Connection",
    icon: LinkedinIcon,
  },
  LINKEDIN_MESSAGE: {
    color: "#1C87EB",
    border: "1px solid #E3F0FD",
    background: "#FAFDFF",
    text: "Message",
    icon: LinkedinIcon,
  },
  START_EMAIL: {
    color: "#8E5AED",
    border: "1px solid #ECE3FC",
    background: "#FCFAFE",
    text: "Start-up mail",
    icon: ColdMailIcon,
  },
  Delivered: {
    color: "#127D4D",
    border: "1px solid #E9F7F0",
    background: "#FBFDFC",
    text: "Delivered",
    icon: DeliveredMailIcon,
  },
};