import fetch from 'auth/FetchInterceptor'

const InboxService = {}

InboxService.getThreads = function (params) {
  return fetch({
    url: '/mail/threads/',
    method: 'get',
    params
  })
}

InboxService.getInboxThreads = function (params, channel_type, type) {
  return fetch({
    url: `/unibox/inbox/channel/${channel_type}/threads`,
    method: 'get',
    params
  })
}

InboxService.getInboxMails = function (channel_type, thread_id) {
  return fetch({
    url: `/unibox/inbox/channel/${channel_type}/thread/${thread_id}/messages`,
    method: 'get'
  })
}

InboxService.getMails = function (id, params) {
    return fetch({
      url:  `/mail/mails/${id}/`,
      method: 'get',
      params
    })
  }

  InboxService.updateThread = function ({id,data}) {
  return fetch({
    url: `/mail/threads/${id}/`,
    method: 'put',
    data: data
  })
}

InboxService.postMail = function (data) {
  return fetch({
    url:  `/mail/email/local/reply_draft/`,
    method: 'post',
    data
  })
}

InboxService.deleteInboxThread = function (channel_type, thread_id) {
  return fetch({
    url: `/unibox/inbox/channel/${channel_type}/thread/${thread_id}/delete`,
    method: 'put'
  })
}

InboxService.bulkDeleteInboxThreads = function (channel_type, params) {
  return fetch({
    url: `/unibox/inbox/channel/${channel_type}/threads/mark-deleted`,
    method: 'put',
    data: params
  })
}

InboxService.bulkUnreadInboxThreads = function (channel_type, params) {
  return fetch({
    url: `/unibox/inbox/channel/${channel_type}/threads/mark-unread`,
    method: 'put',
    data: params
  })
}

InboxService.updateInboxThread = function (channel_type, thread_id, params) {
  return fetch({
    url: `/unibox/inbox/channel/${channel_type}/thread/${thread_id}/update`,
    method: 'put',
    data: params
  })
}

export default InboxService;