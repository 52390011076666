import fetch from "auth/FetchInterceptor";

const OutreachService = {};

OutreachService.outreachInit = function (id) {
  return fetch({
    url: `/outreach/outreach/init?campaignId=${id}`,
    method: "post",
  });
};


OutreachService.getOutreachSequence = function (id) {
  return fetch({
    url: `/outreach/get-outreach-sequence?campaignId=${id}`,
    method: "get",
  });
};

OutreachService.uploadOutreachAttachment = function (data) {
  return fetch({
    url: `/outreach/upload/preview-attachment`,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

OutreachService.addStep = function (data) {
  return fetch({
    url: `/outreach/step`,
    method: "post",
    data: data,
  });
};

OutreachService.updateStep = function (data) {
  return fetch({
    url: `/outreach/step`,
    method: "put",
    data: data,
  });
};

OutreachService.deleteStep = function (data) {
  return fetch({
    url: `/outreach/step/${data?.step_id}`,
    method: "delete",
  });
};

OutreachService.generatePreview = function (data) {
  return fetch({
    url: `/outreach/generate`,
    method: "post",
    data: data,
  });
};

OutreachService.generateTemplate = function (data) {
  return fetch({
    url: `/outreach/template`,
    method: "get",
    params: data
  });
};

// Final submit data
OutreachService.onsubmiteOutreach = function (data) {
  return fetch({
    url: `/outreach/outreach/submit?campaignId=${data}`,
    method: "post",
  });
};




export default OutreachService;
