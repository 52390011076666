import fetch from "auth/FetchInterceptor";

const MailApprovalService = {};

MailApprovalService.getGenerationlogs = function (params) {
  // Construct the base URL
  let url = "/mail/generation-logs/";

  // Check if a search query is present
  if (params && params.search) {
    // Append the search query to the URL
    // url += `?search=${encodeURIComponent(params.search)}`;
  }

  // Make the fetch request
  return fetch({
    url,
    method: "get",
    params: params ? params : undefined,
  });
};

MailApprovalService.getUniboxGenerationlogs = function (channel_type, params, type) {
  return fetch({
    url: `/unibox/approval/channel/${channel_type}/responses`,
    method: "get",
    params,
  });
};

MailApprovalService.getUniboxGenerationResponse = function (thread_id, response_id) {
  return fetch({
    url: `/unibox/approval/thread/${thread_id}/response/${response_id}`,
    method: "get"
  });
};


MailApprovalService.getGenerationResponse = function (id, params) {
  return fetch({
    url: `/mail/generation-response/${id}/`,
    method: "get",
    params,
  });
};

MailApprovalService.generateResponse = function (data) {
  return fetch({
    url: "/generate",
    method: "post",
    data,
  });
};

MailApprovalService.reGenerateResponse = function (data) {
  return fetch({
    url: `/regenerate`,
    method: "post",
    data,
  });
};

MailApprovalService.uniboxReGenerateResponse = function (data) {
  return fetch({
    url: `/unibox/approval/response/regenerate`,
    method: "post",
    data,
  });
};

MailApprovalService.uniboxReGenerateSave = function (data) {
  return fetch({
    url: `/unibox/approval/response/save-regenerated-log`,
    method: "put",
    data,
  });
};

MailApprovalService.uniboxEditSave = function (response_id, data) {
  return fetch({
    url: `/unibox/approval/response/${response_id}/edit`,
    method: "put",
    data,
  });
};

MailApprovalService.updateGenerationResponse = function ({ id, data }) {
  return fetch({
    url: `/mail/generation-response/${id}`,
    method: "POST",
    data,
  });
};

MailApprovalService.approveMail = function (params) {
  return fetch({
    url: `/mail/approve`,
    method: "PUT",
    params,
  });
};

MailApprovalService.approveAllMail = function (params) {
  return fetch({
    url: `/mail/approve/all`,
    method: "PUT",
    params,
  });
};

MailApprovalService.updateGenerationLog = function ({ id, data }) {
  return fetch({
    url: `/mail/generation-logs/${id}/`,
    method: "PUT",
    data,
  });
};

MailApprovalService.bulkDeleteUniboxApprovals = function (params) {
  return fetch({
    url: `/unibox/approval/responses/mark-deleted`,
    method: 'put',
    data: params,
  })
}

MailApprovalService.bulkUnreadUniboxApprovals = function (params) {
  return fetch({
    url: `/unibox/approval/responses/mark-unread`,
    method: 'put',
    data: params,
  })
}

MailApprovalService.markReadApprovalThread = function (response_id) {
  return fetch({
    url: `/unibox/approval/response/${response_id}/mark-read`,
    method: 'put',
  })
}

MailApprovalService.deleteResponse = function (response_id) {
  return fetch({
    url: `/unibox/approval/response/${response_id}/mark-deleted`,
    method: 'put',
  })
}

MailApprovalService.approveResponse = function (response_id) {
  return fetch({
    url: `/unibox/approval/response/${response_id}/approve`,
    method: 'post',
  })
}

MailApprovalService.approveAllResponse = function (channel_type, params) {
  return fetch({
    url: `/unibox/approval/channel/${channel_type}/responses/approve-all`,
    method: 'put',
    data: params,
  })
}

export default MailApprovalService;
