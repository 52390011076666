import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import campaign from "./slices/campaignSlice";
import mailManagenent from "./slices/mailManagementSlice";
import integration from "./slices/integrationSlice";
import metadata from "./slices/metadataSlice";
import inbox from "./slices/inboxSlice";
import leads from "./slices/leadSlice";
import mailGeneration from "./slices/mailApprovalSlice";
import dashboard from "./slices/dashboardSlice";
import profile from "./slices/profileSlice";
import Outreach from "./slices/outreachSlice";

const rootReducer = (asyncReducers) => (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined; // Reset state on logout
  }
  const combinedReducer = combineReducers({
    theme,
    auth,
    campaign,
    mailManagenent,
    metadata,
    integration,
    inbox,
    leads,
    mailGeneration,
    dashboard,
    profile,
    Outreach,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
