import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import InboxService from "services/InboxService";
import { message } from "antd";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  mails: {},
  error: null,
  threads: [],
  postMailRes: {},
  deleteThreadRes: {},
  bulkDeleteThreadRes: {},
  bulkUnreadThreadRes: {},
  updateInboxThreadRes: {},
  updateThreadShowMessage: {
    updated: false,
  },
  count: null,
};

const success = (msg) => {
  message.open({
    type: "success",
    content: msg,
  });
};

// Fetch threads
export const fetchThreads = createAsyncThunk(
  "inbox/fetchThreads",
  async (params, { rejectWithValue }) => {
    try {
      const response = await InboxService.getThreads(params);
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error fetching threads"
      );
    }
  }
);

export const fetchInboxThreads = createAsyncThunk(
  "inbox/fetchInboxThreads",
  async ({params, channel_type, type}, { rejectWithValue }) => {
    try {
      const response = await InboxService.getInboxThreads(params, channel_type, type);
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error fetching threads"
      );
    }
  }
);

export const deleteThread = createAsyncThunk(
  "inbox/deleteThread",
  async ({channel_type, thread_id}, { rejectWithValue }) => {
    try {
      const response = await InboxService.deleteInboxThread(channel_type, thread_id);
      success("Successfully updated");
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error deleting thread"
      );
    }
  }
);

export const bulkDeleteThreads = createAsyncThunk(
  "inbox/bulkDeleteThreads",
  async ({channel_type, params}, { rejectWithValue }) => {
    try {
      const response = await InboxService.bulkDeleteInboxThreads(channel_type, params);
      success("Successfully updated");
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error deleting threads"
      );
    }
  }
);

export const bulkUnreadThreads = createAsyncThunk(
  "inbox/bulkUnreadThreads",
  async ({channel_type, params}, { rejectWithValue }) => {
    try {
      const response = await InboxService.bulkUnreadInboxThreads(channel_type, params);
      success("Successfully updated");
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error unreading threads"
      );
    }
  }
);

export const updateInboxThread = createAsyncThunk(
  "inbox/updateInboxThread",
  async ({channel_type, thread_id, params}, { rejectWithValue }) => {
    try {
      const response = await InboxService.updateInboxThread(channel_type, thread_id, params);
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error updating thread"
      );
    }
  }
);

export const fetchInboxMails = createAsyncThunk(
  "inbox/fetchInboxMails",
  async ({ channel_type, id }, { rejectWithValue }) => {
    try {
      const response = await InboxService.getInboxMails(channel_type, id);
      return response;
    } catch (err) {
      return rejectWithValue(
        err?.response?.data?.message || "Error fetching emails"
      );
    }
  }
);

// Fetch mails
export const fetchMails = createAsyncThunk(
  "inbox/fetchMails",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const response = await InboxService.getMails(id, params);
      return response;
    } catch (err) {
      return rejectWithValue(
        err?.response?.data?.message || "Error fetching emails"
      );
    }
  }
);

export const updateThread = createAsyncThunk(
  "inbox/updateThread",
  async (thread, { rejectWithValue }) => {
    try {
      const response = await InboxService.updateThread(thread);
      if (
        thread?.data?.hasOwnProperty("isDeleted") ||
        thread?.data?.hasOwnProperty("isStarred")
      ) {
        success("Successfully updated");
      }
      return { thread, data: response };
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error toggling mailbox"
      );
    }
  }
);

export const postMail = createAsyncThunk(
  "inbox/postMail",
  async (thread, { rejectWithValue }) => {
    try {
      const response = await InboxService.postMail(thread);
      success("Email sent successfully");
      return { thread, data: response };
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error toggling mailbox"
      );
    }
  }
);

export const inboxSlice = createSlice({
  name: "inbox",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
      state.showMessage = false;
    },
    clearThreads(state) {
      state.threads = [];
      state.mails = {};
      state.count = null;
    },
    clearCount(state) {
      state.count = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch threads
      .addCase(fetchThreads.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchThreads.fulfilled, (state, action) => {
        state.loading = false;
        state.threads = [...state.threads, ...action.payload?.data] || [];
        state.count = action.payload?.count || null;
        state.showMessage = false;
      })
      .addCase(fetchThreads.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      // Fetch Unibox Inbox threads
      .addCase(fetchInboxThreads.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInboxThreads.fulfilled, (state, action) => {
        state.loading = false;
        if (action.meta.arg.type === 'onscroll') {
          state.threads = [...state.threads, ...action.payload?.thread_list] || [];
        } else {
          state.threads = action.payload?.thread_list || [];
        }
        state.count = action.payload?.total_count || null;
        state.showMessage = false;
      })
      .addCase(fetchInboxThreads.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      // Fetch Unibox Inbox mails
      .addCase(fetchInboxMails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInboxMails.fulfilled, (state, action) => {
        state.loading = false;
        state.mails = action.payload;
        state.showMessage = false;
      })
      .addCase(fetchInboxMails.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      // delete Inbox thread
      .addCase(deleteThread.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteThread.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteThreadRes = action.payload;
        // state.showMessage = false;
      })
      .addCase(deleteThread.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      // bulk delete Inbox threads
      .addCase(bulkDeleteThreads.pending, (state) => {
        state.loading = true;
      })
      .addCase(bulkDeleteThreads.fulfilled, (state, action) => {
        state.loading = false;
        state.bulkDeleteThreadRes = action.payload;
        state.showMessage = false;
      })
      .addCase(bulkDeleteThreads.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      // bulk unread Inbox threads
      .addCase(bulkUnreadThreads.pending, (state) => {
        state.loading = true;
      })
      .addCase(bulkUnreadThreads.fulfilled, (state, action) => {
        state.loading = false;
        state.bulkUnreadThreadRes = action.payload;
        state.showMessage = false;
      })
      .addCase(bulkUnreadThreads.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      // updating Inbox thread
      .addCase(updateInboxThread.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateInboxThread.fulfilled, (state, action) => {
        state.loading = false;
        state.updateInboxThreadRes = action.payload;
        state.showMessage = false;
      })
      .addCase(updateInboxThread.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })
      
      .addCase(updateThread.pending, (state) => {
        state.loading = true;
        state.updateThreadShowMessage = {
          updated: false,
        };
      })
      .addCase(updateThread.fulfilled, (state, action) => {
        state.updateThreadShowMessage = {
          updated: true,
          response: action.payload,
        };
        state.loading = false;
      })
      .addCase(updateThread.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.updateThreadShowMessage = {
          updated: false,
        };
      })
      // Toggle mails
      .addCase(fetchMails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMails.fulfilled, (state, action) => {
        state.loading = false;
        state.mails = action.payload;
        state.showMessage = false;
      })
      .addCase(fetchMails.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })
      // Toggle mails
      .addCase(postMail.pending, (state) => {
        state.loading = true;
      })
      .addCase(postMail.fulfilled, (state, action) => {
        state.loading = false;
        state.mails.mails = [
          ...(state.mails.mails || []),
          action.payload.data.email_detail,
        ];
        state.showMessage = false;
      })
      .addCase(postMail.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      });
  },
});

export const { clearError, clearThreads, clearCount } = inboxSlice.actions; // not used yet

export default inboxSlice.reducer;
