import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import MailManagementService from 'services/MailManagementService'

export const initialState = {
  loading: false,
  message: '',
  showMessage: false,
  emails: [],
  error: null,
  authCode: null,
  averageEmailHealth: null
}

// Fetch email addresses
export const fetchEmails = createAsyncThunk('email/fetchEmails', async (_, { rejectWithValue }) => {
  try {
    const response = await MailManagementService.getEmailAddresses()
    return response
  } catch (err) {
    return rejectWithValue(err.response?.data?.message || 'Error fetching emails')
  }
})

export const fectchLinkedInAccounts = createAsyncThunk('email/fetchLinkedInAccounts', async (_, { rejectWithValue }) => {
  try {
    const response = await MailManagementService.getLinkedInAccounts()
    return response
  } catch (err) {
    return rejectWithValue(err.response?.data?.message || 'Error fetching linkedIn accounts')
  }
})

// Toggle use of an email mailbox
export const toggleUseMailbox = createAsyncThunk('email/toggleUseMailbox', async (account, { rejectWithValue }) => {
  try {
    const response = await MailManagementService.toggleUseMailbox(account)
    return { account, data: response.data }
  } catch (err) {
    return rejectWithValue(err.response?.data?.message || 'Error toggling mailbox')
  }
})

// Disconnect email address
export const disconnectEmailAddress = createAsyncThunk('email/disconnectEmailAddress', async (account, { rejectWithValue }) => {
  try {
    const response = await MailManagementService.disconnectEmailAddress(account)
    return { account, data: response.data }
  } catch (err) {
    return rejectWithValue(err.response?.data?.message || 'Error disconnecting email address')
  }
})

// Get auth code
export const getAuthCode = createAsyncThunk('email/getAuthCode', async (provider, { rejectWithValue }) => {
  try {
    const response = await MailManagementService.getAuthCode(provider)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response?.data?.message || 'Failed to get auth code')
  }
})

// Get Email health
export const fetchEmailHealth = createAsyncThunk('email/fetchEmailHealth', async (provider, { rejectWithValue }) => {
  try {
    const response = await MailManagementService.getEmailHealth(provider)
    return response
  } catch (err) {
    return rejectWithValue(err.response?.data?.message || 'Failed to get auth code')
  }
})

export const mailManagementSlice = createSlice({
  name: 'email',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null
      state.showMessage = false
    },
    clearAuthCode(state) {
      state.authCode = null
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch emails
      .addCase(fetchEmails.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchEmails.fulfilled, (state, action) => {
        state.loading = false
        state.emails = action.payload
        state.showMessage = false
      })
      .addCase(fetchEmails.rejected, (state, action) => {
        state.loading = false
        state.message = action.payload
        state.showMessage = true
      })
      // Toggle use mailbox
      .addCase(toggleUseMailbox.pending, (state) => {
        state.loading = true
      })
      .addCase(toggleUseMailbox.fulfilled, (state, action) => {
        state.loading = false
        state.emails = state.emails.map(email =>
          email.account === action.payload.account
            ? { ...email, use_this_mailbox: action.payload.data.use_this_mailbox }
            : email
        )
        state.showMessage = false
      })
      .addCase(toggleUseMailbox.rejected, (state, action) => {
        state.loading = false
        state.message = action.payload
        state.showMessage = true
      })
      .addCase(disconnectEmailAddress.pending, (state) => {
        state.loading = true
      })
      .addCase(disconnectEmailAddress.fulfilled, (state, action) => {
        state.loading = false
        state.emails = state.emails.filter(email => email.account !== action.payload.account)
        state.showMessage = false
      })
      .addCase(disconnectEmailAddress.rejected, (state, action) => {
        state.loading = false
        state.message = action.payload
        state.showMessage = true
      })
      .addCase(getAuthCode.pending, (state) => {
        state.loading = true
      })
      .addCase(getAuthCode.fulfilled, (state, action) => {
        state.loading = false
        state.authCode = action.payload
        state.showMessage = false
      })
      .addCase(getAuthCode.rejected, (state, action) => {
        state.loading = false
        state.message = action.payload
        state.showMessage = true
      })
      // Avarage Email Health
      .addCase(fetchEmailHealth.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchEmailHealth.fulfilled, (state, action) => {
        state.loading = false
        state.averageEmailHealth = action.payload
        state.showMessage = false
      })
      .addCase(fetchEmailHealth.rejected, (state, action) => {
        state.loading = false
        state.message = action.payload
        state.showMessage = true
      })
  }
})

export const { clearError, clearAuthCode } = mailManagementSlice.actions // not used yet

export default mailManagementSlice.reducer