import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import OutreachService from "services/OutreachService";

export const initialState = {
  loading: false,
  outreachLoading: {
    isloading: false,
    stepIndex: null
  },
  showMessage: "",
  error: null,
  sequence: [],
  generateOutreachPreview: [],
  outreachTemplate: []
};

export const outreachInit = createAsyncThunk(
  "outreach/outreachInit",
  async (data) => {
    try {
      const response = await OutreachService.outreachInit(data);
      return response;
    } catch (err) {
      return err.response?.data?.message || "Error";
    }
  }
);

export const fetchOutreachSequence = createAsyncThunk(
  "outreach/fetchOutreachSequence",
  async (id) => {
    try {
      const response = await OutreachService.getOutreachSequence(id);
      return response;
    } catch (err) {
      return err.response?.data?.message || "Error";
    }
  }
);

export const uploadOutreachattachment = createAsyncThunk(
  "outreach/uploadOutreachattachment",
  async (data) => {
    try {
      const formData = new FormData();
      formData.append("file", data);
      const response = await OutreachService.uploadOutreachAttachment(formData);
      return response;
    } catch (err) {
      return err.response?.data?.message || "Error";
    }
  }
);

export const onsubmite_Outreach = createAsyncThunk(
  "outreach/onsubmite_Outreach",
  async (id) => {
    try {
      const formData = new FormData();
      formData.append("file", id);
      const response = await OutreachService.onsubmiteOutreach(id);
      return response;
    } catch (err) {
      return err.response?.data?.message || "Error";
    }
  }
);
 
export const addStep = createAsyncThunk("outreach/addStep", async (data) => {
  console.log("data----", data);

  try {
    const response = await OutreachService.addStep(data);
    return { ...response, stepId: data?.id };
  } catch (err) {
    return err.response?.data?.message || "Error";
  }
});

export const generatePreview = createAsyncThunk(
  "outreach/generatePreview",
  async (data) => {

    try {
      const response = await OutreachService.generatePreview(data);
      return { ...response, id: data?.step_id };
    } catch (err) {
      return err.response?.data?.message || "Error";
    }
  }
);

export const generateTemplate = createAsyncThunk(
  "outreach/generateTemplate",
  async (data) => {
    try {
      const response = await OutreachService.generateTemplate(data);
      return response
    } catch (err) {
      return err.response?.data?.message || "Error";
    }
  }
);

export const onSaveFinalData = createAsyncThunk(
  "outreach/onSaveFinalData",
  async (data) => {
    try {
      const response = await OutreachService.updateStep(data);
      return response?.data

    } catch (err) {
      return err.response?.data?.message || "Error";
    }
  }
);

export const onDeleteStepWithId = createAsyncThunk(
  "outreach/onDeleteStepWithId",
  async (data) => {
    try {
      const response = await OutreachService.deleteStep(data);
      return response?.data
    } catch (err) {
      return err.response?.data?.message || "Error";
    }
  }
);

export const OutreachSlice = createSlice({
  name: "outreach",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
      state.showMessage = false;
    },
    cancelGeneration(state, action) {
      const idToRemove = action.payload; // The id to remove
      state.generateOutreachPreview = state.generateOutreachPreview.filter(
        (item) => item.id !== idToRemove
      );
    },
    setGenerationLoading: (state, action) => {
      state.outreachLoading.isloading = true
      state.outreachLoading.stepIndex = action?.payload?.step_index
    },
    cleaeSequence: (state) => {
      state.sequence = []
    }
  },
  extraReducers: (builder) => {
    builder
      //People:- get jobTitles
      .addCase(fetchOutreachSequence.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOutreachSequence.fulfilled, (state, action) => {
        state.loading = true;
        state.sequence = action.payload;
      })
      .addCase(fetchOutreachSequence.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })

      .addCase(addStep.pending, (state) => {
        state.loading = true;
      })
      .addCase(addStep.fulfilled, (state, action) => {
        state.loading = true;
        const index = state.sequence.findIndex(
          (item) => item.id === action.payload.stepId
        );
        console.log("index", index, "action.payload", action.payload);
        const updatedPayload = { ...action.payload?.data }; // Clone the payload to avoid mutations
        updatedPayload.id = action.payload?.id;

        if (index !== -1) {
          // Insert the new object right after the matched id's index
          state.sequence.splice(index + 1, 0, updatedPayload);
        } else {
          // If id is not found, add to the end of the array
          state.sequence.push(updatedPayload);
        }
      })
      .addCase(addStep.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })

      // Generation data from api call
      .addCase(generatePreview.pending, (state, action) => {
        state.outreachLoading.isloading = true
      })
      .addCase(generatePreview.fulfilled, (state, action) => {
        state.outreachLoading.isloading = false
        state.outreachLoading.stepIndex = null
        const existingIndex = state.generateOutreachPreview.findIndex(
          (item) => item.id === action.payload.id
        );

        if (existingIndex !== -1) {
          // Override the existing object
          state.generateOutreachPreview[existingIndex] = action.payload;
        } else {
          // Append the new object
          state.generateOutreachPreview = [
            ...state.generateOutreachPreview,
            action.payload,
          ];
        }
      })
      .addCase(generatePreview.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.outreachLoading.isloading = false
        state.outreachLoading.stepIndex = null
      })

      .addCase(generateTemplate.pending, (state) => {
        state.loading = true;
        state.outreachLoading.isloading = true
      })
      .addCase(generateTemplate.fulfilled, (state, action) => {
        state.outreachLoading.isloading = false
        state.outreachLoading.stepIndex = null
        state.outreachTemplate = false
        state.outreachTemplate = action?.payload
      })
      .addCase(generateTemplate.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.outreachLoading.isloading = false
        state.outreachLoading.stepIndex = null
      });
  },
});

export const { clearError, cancelGeneration, setGenerationLoading, cleaeSequence } = OutreachSlice.actions;

export default OutreachSlice.reducer;
